import {
  alias,
  getAnonymousId,
  identify,
  IdentifyTraits,
  resetAnonymousId,
  setAnonymousId,
  track,
} from '@hbf/analytics';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import {
  getUserId,
  getIsAliased,
  getUserIdAlias,
  getUserTraits,
  getAnalyticsUserData,
  getUserUUID,
} from 'ha/modules/AuthLogic/selectors';
import { Thunk } from 'ha/myredux/types';
import { CookieConsent } from 'ha/services/analytics/types';

import { EMPTY_USER_ID } from './constants';
import {
  identifyAnonymously,
  identifyRockerbox,
  identifyWithRockerbox,
} from './utils';

export const identifyUser =
  (
    {
      isCookieConsentEnabled,
      isAnonymousTrackingEnabled,
      hasUserRejectedAllCategories,
    }: CookieConsent,
    userTraits: IdentifyTraits,
  ): Thunk =>
  (_, getState, { analytics }) => {
    const state = getState();

    const userId = getUserId(state);

    const withAnonymousTracking =
      isCookieConsentEnabled && isAnonymousTrackingEnabled;

    if (withAnonymousTracking) {
      resetAnonymousId(hasUserRejectedAllCategories);
    }

    if (!userId) {
      // Needs to call identify, to set new anonymous id on device mode destinations
      // if userId is not presented, anonymousId will be used instead
      identify(EMPTY_USER_ID, userTraits);
      analytics.userIdentificationComplete();
      return;
    }

    const userIdAlias = getUserIdAlias(state);

    const anonymousId = getAnonymousId()!;

    console.debug('analytics', 'identify', 'user id and anonymous id', {
      userIdAlias,
      anonymousId,
    });

    // unset anonymous id after first page loaded
    if (userIdAlias !== anonymousId) {
      if (anonymousId?.startsWith('u')) {
        track('DEBUG', {
          severity: 'ERROR',
          code: 'ANALYTICS_IDENTITY_INVALID_ANONYMOUS_ID',
          message:
            'Analytics: identify called with anonymous id being a different user alias',
          data: {
            userIdAlias,
            anonymousId,
          },
        });
        reportError(
          'Analytics: identify called with anonymous id being a different user alias',
        );
      } else {
        console.debug(
          'analytics',
          'identify',
          `setting anonymous id to user id alias "${userIdAlias}", previously "${anonymousId}"`,
        );
        // alias anonymous id to user id => triggers merge without error
        alias(anonymousId, userIdAlias);
        // set anonymous id to user id => would not trigger merge moving forward
        setAnonymousId(userIdAlias);
      }
    }

    const userTraitsBaseline = getUserTraits(state);
    const userDataAnalytics = getAnalyticsUserData(state);
    const identifyPayload: IdentifyTraits = {
      ...userTraitsBaseline,
      ...userDataAnalytics,
      ...userTraits,
    };
    const userUUID = getUserUUID(state);
    const isAliased = getIsAliased(state);

    if (withAnonymousTracking && hasUserRejectedAllCategories) {
      // authenticated user id with anonymous tracking

      identifyRockerbox(userUUID, identifyPayload);

      identifyAnonymously(identifyPayload);

      analytics.userIdentificationComplete();
      return;
    }

    // Verify if merging a new identity with an existing user is required
    if (isAliased) {
      identifyWithRockerbox(userUUID, identifyPayload, userIdAlias);

      analytics.userIdentificationComplete();
    } else {
      alias(userIdAlias);

      setTimeout(() => {
        identifyWithRockerbox(userUUID, identifyPayload, userIdAlias);

        analytics.userIdentificationComplete();
      }, 2000);
    }
  };
